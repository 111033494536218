import algoliasearch, { AlgoliaSearchOptions, SearchClient, SearchIndex } from 'algoliasearch/lite';
import { AlgoliaConfig } from '../types/auth/auth.type';
import aa, { InsightsClient, InsightsMethodMap } from 'search-insights';
import { getAccessToken } from '../utils/get-access-token';
import { RequestOptions } from 'https';
import recommend, {
  RecommendClient,
  RecommendationsQuery,
  RecommendedForYouQuery,
  TrendingQuery,
} from '@algolia/recommend';
import { AlgoliaTimeout } from 'lib/constants';
import { checkIfBase64TokenValid } from '../utils/check-expiration';

export type Algolia = {
  client: SearchClient;
  recommendClient: RecommendClient;
  index: SearchIndex;
  productSuggestionIndex: SearchIndex;
  sendEvents: InsightsClient;
  apiKey: string;
  productIndexName: string;
  sortOptionToAlgoliaReplicaIndexesMap: {
    default: string;
    price_asc: string;
    price_desc: string;
    en_brand_asc: string;
    en_brand_desc: string;
    ar_brand_asc: string;
    ar_brand_desc: string;
  };
};

export let algolia: Algolia | null = null;

const refreshAlgoliaIfneeded = async (
  currentApiKey: string,
  setAlgolia?: React.Dispatch<React.SetStateAction<Algolia>>,
) => {
  if (!setAlgolia || checkIfBase64TokenValid(currentApiKey)) {
    return null;
  }

  const { algoliaConfig } = await getAccessToken();
  const updatedAlgolia = initializeAlgoliaClient(algoliaConfig, true, setAlgolia);
  setAlgolia(updatedAlgolia);
  return updatedAlgolia;
};

const createRecommendClient = (
  { applicationId, apiKey }: AlgoliaConfig,
  setAlgolia?: React.Dispatch<React.SetStateAction<Algolia>>,
) => {
  const algoliaRecommendedCient: RecommendClient = recommend(applicationId, apiKey, {
    timeouts: { read: AlgoliaTimeout, connect: AlgoliaTimeout, write: AlgoliaTimeout },
  });

  const recommendClient: RecommendClient = {
    ...algoliaRecommendedCient,
    getTrendingItems: async (queries: any, requestOptions?: any) => {
      const updatedAlgolia = await refreshAlgoliaIfneeded(apiKey, setAlgolia);
      if (updatedAlgolia) {
        return updatedAlgolia.recommendClient.getTrendingItems(queries, requestOptions);
      }
      return algoliaRecommendedCient.getTrendingItems(queries, requestOptions);
    },
    getTrendingFacets: async (queries: any, requestOptions?: any) => {
      const updatedAlgolia = await refreshAlgoliaIfneeded(apiKey, setAlgolia);
      if (updatedAlgolia) {
        return updatedAlgolia.recommendClient.getTrendingFacets(queries, requestOptions);
      }
      return algoliaRecommendedCient.getTrendingFacets(queries, requestOptions);
    },

    getRecommendations: async (
      queries: ReadonlyArray<RecommendationsQuery | TrendingQuery | RecommendedForYouQuery>,
      requestOptions?: RequestOptions & AlgoliaSearchOptions,
    ) => {
      const updatedAlgolia = await refreshAlgoliaIfneeded(apiKey, setAlgolia);
      if (updatedAlgolia) {
        return updatedAlgolia.recommendClient.getRecommendations(queries, requestOptions);
      }
      return algoliaRecommendedCient.getRecommendations(queries, requestOptions);
    },
  };

  return recommendClient;
};

const createAlgoliaClient = (
  { applicationId, apiKey }: AlgoliaConfig,
  setAlgolia?: React.Dispatch<React.SetStateAction<Algolia>>,
) => {
  const algoliaSearchCient: SearchClient = algoliasearch(applicationId, apiKey, {
    timeouts: { read: AlgoliaTimeout, connect: AlgoliaTimeout, write: AlgoliaTimeout },
  });

  const client: SearchClient = {
    ...algoliaSearchCient,
    initIndex(indexName) {
      const index = algoliaSearchCient.initIndex(indexName);
      return {
        ...index,
        search: async (query: string, requestOptions?: RequestOptions & AlgoliaSearchOptions): Promise<any> => {
          const updatedAlgolia = await refreshAlgoliaIfneeded(apiKey, setAlgolia);
          if (updatedAlgolia) {
            return updatedAlgolia.index.search(query, requestOptions);
          }
          return index.search(query, requestOptions);
        },
      };
    },
  };

  return client;
};

const initEvents = async ({ applicationId, apiKey }: AlgoliaConfig) => {
  aa('init', {
    appId: applicationId,
    apiKey: apiKey,
    useCookie: true,
  });
};

export const initializeAlgoliaClient = (
  algoliaConfig: AlgoliaConfig,
  forceUpdateAlgoliaClient = false,
  setAlgolia?: React.Dispatch<React.SetStateAction<Algolia>>,
) => {
  if (algolia && !forceUpdateAlgoliaClient) {
    return algolia;
  }

  if (!algoliaConfig) return algolia;;

  initEvents(algoliaConfig);

  const client = createAlgoliaClient(algoliaConfig, setAlgolia);

  const sendEvents = async <MethodName extends keyof InsightsMethodMap>(
    method: MethodName,
    ...args: InsightsMethodMap[MethodName]
  ) => {
    const updatedAlgolia = await refreshAlgoliaIfneeded(algoliaConfig.apiKey, setAlgolia);
    if (updatedAlgolia) {
      initEvents(algoliaConfig);
    }
    aa(method, ...args);
  };

  algolia = {
    apiKey: algoliaConfig.apiKey,
    client,
    recommendClient: createRecommendClient(algoliaConfig, setAlgolia),
    index: client.initIndex(algoliaConfig.productIndexName),
    productSuggestionIndex: client.initIndex(
      process.env.NEXT_PUBLIC_PRODUCT_SUGGESTION_INDEX ?? 'prod_alhabib_products_query_suggestions',
    ),
    sendEvents,
    productIndexName: algoliaConfig.productIndexName,
    sortOptionToAlgoliaReplicaIndexesMap: {
      default: process.env.NEXT_PUBLIC_DEFAULT_INDEX ?? 'prod_alhabib_products',
      price_asc: process.env.NEXT_PUBLIC_PRICE_ASC_INDEX ?? 'prod_alhabib_products_price_asc',
      price_desc: process.env.NEXT_PUBLIC_PRICE_DESC_INDEX ?? 'prod_alhabib_products_price_desc',
      en_brand_asc: process.env.NEXT_PUBLIC_EN_BRAND_ASC_INDEX ?? 'prod_alhabib_products_en_brand_asc',
      en_brand_desc: process.env.NEXT_PUBLIC_EN_BRAND_DESC_INDEX ?? 'prod_alhabib_products_en_brand_desc',
      ar_brand_asc: process.env.NEXT_PUBLIC_AR_BRAND_ASC_INDEX ?? 'prod_alhabib_products_ar_brand_asc',
      ar_brand_desc: process.env.NEXT_PUBLIC_AR_BRAND_DESC_INDEX ?? 'prod_alhabib_products_ar_brand_desc',
    },
  };

  return algolia;
};

export const getAlgolia = () => algolia as Algolia;
